<template>
  <div>
    <vs-button
      style="float: left;"
      icon-pack="feather"
      icon="icon-arrow-up-right"
      class="m-1"
      @click="showTransferAccountPopup"
    >
      Transfer Account
    </vs-button>

    <vs-popup title="Transfer Account" :active.sync="popupActivo">
      <form autocomplete="off" @submit.prevent="processTransfer">
        <vs-input
          :success="
            !errors.first('basic.firstName') && clinicData.firstName != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.firstName') ? true : false"
          v-validate="'required|max:150'"
          name="firstName"
          data-vv-as="first name"
          label="First Name"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.firstName')"
          v-model="clinicData.firstName"
        />

        <vs-input
          :success="
            !errors.first('basic.lastName') && clinicData.lastName != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.lastName') ? true : false"
          v-validate="'required|max:150'"
          name="lastName"
          data-vv-as="last name"
          label="Last Name"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.lastName')"
          v-model="clinicData.lastName"
        />

        <vs-input
          :success="!errors.first('basic.email') && clinicData.email != ''"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.email') ? true : false"
          v-validate="'required|email|max:150'"
          name="email"
          data-vv-as="email"
          label="Email"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.email')"
          v-model="clinicData.email"
        />

        <vs-button
          size="normal"
          @click="processTransfer"
          icon-pack="feather"
          class="mr-2 mt-3"
          >Transfer</vs-button
        >
      </form>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TransferAccount",
  props: {
    clinicId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      popupActivo: false,
      clinicData: {
        firstName: "",
        lastName: "",
        email: ""
      }
    };
  },
  methods: {
    ...mapActions("clinic", ["transferAccount"]),
    showTransferAccountPopup() {
      this.popupActivo = true;
    },
    async processTransfer() {
      let isValid = await this.$validator.validateAll("basic");

      if (isValid) {
        this.$vs.loading();
        this.clinicData.clinicId = this.clinicId;
        this.transferAccount(this.clinicData)
          .then(res => {
            this.$emit("account-transferred", res);
            this.popupActivo = false;
            this.clinicData = {
              firstName: "",
              lastName: "",
              email: ""
            };

            this.$vs.notify({
              title: res.data.title,
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-check-circle",
              color: "success"
            });
            this.$vs.loading.close();
          })
          .catch(err => {
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
            this.$vs.loading.close();
          });
      }
    }
  }
};
</script>
