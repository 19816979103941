<template>
  <div>
    <vs-card>
      <div slot="header" style="display: flex; justify-content: space-between">
        <p
          style="
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          @click="$router.push({ name: backRoute })"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            svgClasses="h-4 w-4"
            class="mr-2 cursor-pointer"
          ></feather-icon
          >Clinic List
        </p>
        <p
          v-if="showHideIcon && check_has_permission('updateClinicManagement')"
          style="
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #3dc9b3;
          "
          @click="$router.push({ name: clinicEditRoute })"
        >
          <feather-icon
            icon="EditIcon"
            svgClasses="h-4 w-4"
            class="mr-2 cursor-pointer"
          ></feather-icon
          >Edit
        </p>
      </div>
      <vs-row
        v-if="canTransferAccount"
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
      >
        <transfer-account
          :clinic-id="clinicData.clinicId"
          @account-transferred="accountTransferred"
        />
      </vs-row>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="firstName">First Name</label>
            <p id="firstName">{{ clinicData.firstName }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="lastName">Last Name</label>
            <p id="lastName">{{ clinicData.lastName }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="clinicName">Clinic Name</label>
            <p v-if="!clinicData.clinicName">N/A</p>
            <p v-else id="clinicName">{{ clinicData.clinicName }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="email">Email</label>
            <p id="email">{{ clinicData.email }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="email">Email</label>
            <div style="display: flex">
              <p id="email" style="margin-right: 5px">{{ clinicData.email }}</p>
              <vx-tooltip
                v-if="clinicData.emailVerifiedAt"
                text="Verified"
                style="display: flex"
              >
                <feather-icon
                  style="color: rgba(var(--vs-primary), 1) !important"
                  icon="CheckCircleIcon"
                  svgClasses="h-5 w-5"
                  class="mr-2 cursor-pointer"
                ></feather-icon>
              </vx-tooltip>
              <vx-tooltip v-else text="Unverified" style="display: flex">
                <feather-icon
                  style="color: rgba(var(--vs-danger), 1) !important"
                  icon="AlertCircleIcon"
                  svgClasses="h-5 w-5"
                  class="mr-2 cursor-pointer"
                ></feather-icon>
              </vx-tooltip>
              <VerifyEmail
                @onSuccessfullyVerified="onSuccessfullyVerified"
                v-if="!clinicData.emailVerifiedAt"
                :userId="clinicData.clinicId"
              />
            </div>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="companyName">Company Name</label>
            <p v-if="!clinicData.companyName">N/A</p>
            <p v-else id="companyName">{{ clinicData.companyName }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="clinicName">Clinic Name</label>
            <p v-if="!clinicData.clinicName">N/A</p>
            <p v-else id="clinicName">{{ clinicData.clinicName }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="abn">ABN</label>
            <p v-if="!clinicData.abn">N/A</p>
            <p v-else id="abn">{{ clinicData.abn }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="abnCertificate">ABN Certificate</label>

            <div v-if="clinicData.abnCertificate">
              <div
                v-if="determineFileType(clinicData.abnCertificate) === 'pdf'"
                style="width:50%;"
              >
                <a :href="clinicData.abnCertificate" target="_blank"
                  >Click here to view file</a
                >
              </div>
              <div v-else class="register-img-wrapper">
                <a :href="clinicData.abnCertificate" target="_blank">
                  <img :src="clinicData.abnCertificate" class="img-fluid" />
                </a>
              </div>
            </div>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="address">Address</label>
            <p id="address">
              {{
                clinicData.address ? clinicData.address.displayAddress : "NA"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="suburb">Suburb</label>
            <p>
              {{
                clinicData.address ? clinicData.address.suburb || "N/A" : "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="postCode">Post Code</label>
            <p>
              {{
                clinicData.address
                  ? clinicData.address.postCode || "N/A"
                  : "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="state">State</label>
            <p>
              {{
                clinicData.address ? clinicData.address.state || "N/A" : "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="postalAddress">Postal Address</label>
            <p id="postalAddress">{{ clinicData.postalAddress || "N/A" }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="bestContactName">Best Contact Name</label>
            <p id="bestContactName">
              {{ clinicData.bestContactName || "N/A" }}
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="bestContactEmail">Best Contact Email</label>
            <p id="bestContactEmail">
              {{ clinicData.bestContactEmail || "N/A" }}
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="bestContactNumber">Best Contact Number</label>
            <p id="bestContactNumber">
              {{ clinicData.bestContactNumber || "N/A" }}
            </p>
          </div>
        </vs-col>
        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="invoiceEmail">Invoice Email</label>
            <p id="invoiceEmail">{{ clinicData.invoiceEmail || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="status">Clinic Status</label>
            <p v-if="clinicData.status === 'Pending_Approval'" class="text-sm">
              Pending Approval
            </p>
            <p v-else class="text-sm">{{ clinicData.status }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="hasApprovedByAdmin">Admin Approval</label>
            <p v-if="clinicData.hasApprovedByAdmin === true" class="text-sm">
              Approved
            </p>
            <p v-else class="text-sm">Unapproved</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="contactNumber">Phone</label>
            <p>{{ clinicData.contactNumber || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="instagram">Instagram</label>
            <p>{{ clinicData.instagram || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="facebook">Facebook</label>
            <p>{{ clinicData.facebook || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="website">Website</label>
            <p>{{ clinicData.website || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="clinicAddress1">Clinic Address 1</label>
            <p>{{ clinicData.clinicAddress1 || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="isResidentialAddress">Is Residential Address</label>
            <p>{{ clinicData.isResidentialAddress || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="haveAdrenalineAndHyalaseInClinic"
              >Have Adrenaline And Hyalase In Clinic</label
            >
            <p>{{ clinicData.haveAdrenalineAndHyalaseInClinic || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="intendToSecureS4Medication"
              >Intend To Secure S4 Medication</label
            >
            <p>{{ clinicData.intendToSecureS4Medication || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Council Permits</label>
            <ClinicPhotos
              v-if="
                clinicData.councilPermits &&
                  clinicData.councilPermits.length > 0
              "
              :clinicPhotosField="clinicData.councilPermits"
              from="details"
              loadingClass="councilPermits"
              label="Please upload any required council permits (Optional)"
              popupTitle="Councli Permits"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Poisons Certificate</label>
            <ClinicPhotos
              v-if="
                clinicData.poisonsCertificate &&
                  clinicData.poisonsCertificate.length > 0
              "
              :clinicPhotosField="clinicData.poisonsCertificate"
              from="details"
              loadingClass="poisonsCertificate"
              label="Please upload any required council permits (Optional)"
              popupTitle="Poisons Certificate"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Front Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicFrontPhoto &&
                  clinicData.clinicFrontPhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicFrontPhoto"
              from="details"
              loadingClass="clinicFrontPhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Front Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Floor Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicFloorPhoto &&
                  clinicData.clinicFloorPhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicFloorPhoto"
              from="details"
              loadingClass="clinicFloorPhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Floor Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Treatment Bed Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicTreatmentBedPhoto &&
                  clinicData.clinicTreatmentBedPhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicTreatmentBedPhoto"
              from="details"
              loadingClass="clinicTreatmentBedPhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Treatment Bed Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Drawing Up Area Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicDrawingUpAreaPhoto &&
                  clinicData.clinicDrawingUpAreaPhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicDrawingUpAreaPhoto"
              from="details"
              loadingClass="clinicDrawingUpAreaPhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Drawing Up Area Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Fridge Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicFridgePhoto &&
                  clinicData.clinicFridgePhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicFridgePhoto"
              from="details"
              loadingClass="clinicFridgePhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Fridge Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Drug Storage Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicDrugStoragePhoto &&
                  clinicData.clinicDrugStoragePhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicDrugStoragePhoto"
              from="details"
              loadingClass="clinicDrugStoragePhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Drug Storage Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Bins Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicBinsPhoto &&
                  clinicData.clinicBinsPhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicBinsPhoto"
              from="details"
              loadingClass="clinicBinsPhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Bins Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Room Lock Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicRoomLockPhoto &&
                  clinicData.clinicRoomLockPhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicRoomLockPhoto"
              from="details"
              loadingClass="clinicRoomLockPhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Room Lock Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col
          v-if="!isClinicJoinRequest"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label>Clinic Epipen And Hyalase Photo</label>
            <ClinicPhotos
              v-if="
                clinicData.clinicEpipenAndHyalasePhoto &&
                  clinicData.clinicEpipenAndHyalasePhoto.length > 0
              "
              :clinicPhotosField="clinicData.clinicEpipenAndHyalasePhoto"
              from="details"
              loadingClass="clinicEpipenAndHyalasePhoto"
              label="Please upload any required council permits (Optional)"
              popupTitle="Clinic Epipen And Hyalase Photo"
            />

            <p v-else>N/A</p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        v-if="!isClinicJoinRequest"
      >
        <div class="clinicOpeningHoursDiv">
          <span>Clinic Opening Hours</span>

          <vs-row>
            <vs-col
              v-if="
                clinicData.clinicOpeningHours &&
                  clinicData.clinicOpeningHours.mondays
              "
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full m-5">
                    <label>Mondays</label>
                    <p>{{ clinicData.clinicOpeningHours.mondays || "N/A" }}</p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full m-5">
                    <label>Tuesdays</label>
                    <p>{{ clinicData.clinicOpeningHours.tuesdays || "N/A" }}</p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full m-5">
                    <label>Wednesdays</label>
                    <p>
                      {{ clinicData.clinicOpeningHours.wednesdays || "N/A" }}
                    </p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full m-5">
                    <label>Thursdays</label>
                    <p>
                      {{ clinicData.clinicOpeningHours.thursdays || "N/A" }}
                    </p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full m-5">
                    <label>Fridays</label>
                    <p>{{ clinicData.clinicOpeningHours.fridays || "N/A" }}</p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full m-5">
                    <label>Saturdays</label>
                    <p>
                      {{ clinicData.clinicOpeningHours.saturdays || "N/A" }}
                    </p>
                  </div>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="w-full m-5">
                    <label>Sundays</label>
                    <p>{{ clinicData.clinicOpeningHours.sundays || "N/A" }}</p>
                  </div>
                </vs-col>
              </vs-row>
            </vs-col>

            <vs-col
              v-else
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-w="12"
            >
            </vs-col>
          </vs-row>
        </div>
      </vs-row>

      <div
        v-if="
          $store.state.AppActiveUser.userType !== 'doctor' &&
            $store.state.AppActiveUser.userType !== 'nurse'
        "
      >
        <vs-divider
          v-if="
            !isClinicJoinRequest && check_has_permission('viewClinicBankDetail')
          "
        />

        <div
          v-if="
            !isClinicJoinRequest && check_has_permission('viewClinicBankDetail')
          "
          style="display: flex; justify-content: space-between;"
        >
          <h4>Bank Detail</h4>
          <vs-button
            v-if="
              clinicData.financialDetails &&
                clinicData.financialDetails.accountHolderName &&
                ($store.state.AppActiveUser.userType === 'admin' ||
                  $store.state.AppActiveUser.userType === 'superAdmin')
            "
            color="danger"
            type="filled"
            ref="loadableButton"
            id="delete-bank-details"
            class="vs-con-loading__container"
            @click="popUpDelete = true"
            :disabled="disabled"
            >Remove Details</vs-button
          >
        </div>
        <vs-popup :active.sync="popUpDelete" title="Confirmation">
          <p align="center">
            Are you sure you want to remove payment details from this account?
          </p>
          <br />
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
            vs-mt="6"
          >
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  size="lg"
                  color="primary"
                  @click="removeBankDetails(clinicData.clinicId)"
                  >Yes, Remove</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button size="lg" color="danger" @click="popUpDelete = false"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </vs-popup>

        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
          v-if="
            !isClinicJoinRequest && check_has_permission('viewClinicBankDetail')
          "
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label for="accountHolderName">Account Holder Name</label>
              <p id="accountHolderName">
                {{
                  clinicData.financialDetails &&
                  clinicData.financialDetails.accountHolderName
                    ? clinicData.financialDetails.accountHolderName
                    : "N/A"
                }}
              </p>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label for="bsb">BSB</label>
              <p id="bsb">
                {{
                  clinicData.financialDetails && clinicData.financialDetails.bsb
                    ? clinicData.financialDetails.bsb
                    : "N/A"
                }}
              </p>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label for="accountNumber">Account Number</label>
              <p id="accountNumber">
                {{
                  clinicData.financialDetails &&
                  clinicData.financialDetails.accountNumber
                    ? clinicData.financialDetails.accountNumber
                    : "N/A"
                }}
              </p>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <vs-row v-if="isClinicJoinRequest">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <div class="buttons" style="margin-bottom: 15px">
              <div v-if="!alreadyResponded">
                <vs-button @click="respondInvitation('Accepted')"
                  >Approve</vs-button
                >
                <vs-button
                  @click="respondInvitation('Rejected')"
                  color="danger"
                  style="margin-left: 15px"
                  >Reject</vs-button
                >
              </div>

              <div v-else>
                <p style="color: maroon">{{ alreadyRespondedMessage }}</p>
              </div>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>

<script>
import VerifyEmail from "@/views/general-component/VerifyEmail";
import { mapActions } from "vuex";
import ClinicPhotos from "./ClinicPhotos";
import { EventBus } from "../../../main";
import TransferAccount from "./TransferAccount";

export default {
  name: "DoctorClinicDetail",
  components: {
    VerifyEmail,
    ClinicPhotos,
    TransferAccount
  },
  props: {
    backRoute: {
      type: String,
      default: "SuperAdminClinic"
    },
    clinicEditRoute: {
      type: String,
      default: "SuperAdminClinicEdit"
    },
    isClinicJoinRequest: {
      type: Boolean,
      default: false
    },
    alreadyRespondedMessage: {
      type: String,
      default: ""
    },
    alreadyResponded: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showHideIcon: false,
    popUpDelete: false,
    disabled: false,
    clinicData: {
      clinicId: "",
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      clinicName: "",
      abn: "",
      address: "",
      postalAddress: "",
      bestContactName: "",
      bestContactEmail: "",
      bestContactNumber: "",
      billingEmail: "",
      invoiceEmail: "",
      status: "",
      hasApprovedByAdmin: "",
      emailVerifiedAt: null,
      financialDetails: {
        accountHolderName: "",
        bsb: "",
        accountNumber: ""
      },
      contactNumber: "",
      instagram: "",
      facebook: "",
      website: "",
      clinicAddress1: "",
      isResidentialAddress: "",
      haveAdrenalineAndHyalaseInClinic: "",
      intendToSecureS4Medication: "",
      abnCertificate: "",
      councilPermits: [],
      poisonsCertificate: [],
      clinicOpeningHours: {
        mondays: "",
        tuesdays: "",
        wednesdays: "",
        thursdays: "",
        fridays: "",
        saturdays: "",
        sundays: ""
      },
      clinicFrontPhoto: [],
      clinicFloorPhoto: [],
      clinicTreatmentBedPhoto: [],
      clinicDrawingUpAreaPhoto: [],
      clinicFridgePhoto: [],
      clinicDrugStoragePhoto: [],
      clinicBinsPhoto: [],
      clinicRoomLockPhoto: [],
      clinicEpipenAndHyalasePhoto: []
    }
  }),
  computed: {
    canTransferAccount() {
      return (
        !this.isClinicJoinRequest &&
        ["superAdmin", "admin"].includes(
          this.$store.state.AppActiveUser.userType
        )
      );
    }
  },
  methods: {
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    ...mapActions("general", ["removeUserBankDetails"]),
    accountTransferred(clinic) {
      const clinicData = clinic.data.data;
      this.setClinicData(clinicData);
    },
    removeBankDetails(userId) {
      this.popUpDelete = false;
      this.disabled = true;
      this.$vs.loading({
        background: "danger",
        color: "#fff",
        container: "#delete-bank-details",
        scale: 0.45
      });

      this.removeUserBankDetails({ userId, type: "clinic" })
        .then(response => {
          this.popUpDelete = false;
          this.disabled = false;
          this.$vs.loading.close("#delete-bank-details > .con-vs-loading");
          this.clinicData.financialDetails = {};
        })
        .catch(() => {
          this.popUpDelete = false;
          this.disabled = false;
          this.$vs.loading.close("#delete-bank-details > .con-vs-loading");
          this.$vs.notify({
            title: "Failed",
            text: "Oops! something went wrong",
            color: "danger"
          });
        });
    },
    async getClinicDetail(id) {
      let self = this;
      await this.fetchClinicsDetail(id).then(res => {
        const clinic = res.data.data;
        this.setClinicData(clinic);
      });
    },
    setClinicData(clinic) {
      const self = this;

      if (clinic.permissions) {
        this.$store.commit("UPDATE_USER_PERMISSION", clinic.permissions);
      }

      self.clinicData.clinicId = clinic._id;
      self.clinicData.firstName = clinic.firstName;
      self.clinicData.lastName = clinic.lastName;
      self.clinicData.email = clinic.email;
      self.clinicData.bestContactNumber = clinic.bestContactNumber;
      self.clinicData.companyName = clinic.companyName;

      self.clinicData.clinicName = clinic.clinicName;
      self.clinicData.abn = clinic.abn;
      self.clinicData.address = clinic.address;
      self.clinicData.postalAddress = clinic.postalAddress;

      self.clinicData.bestContactName = clinic.bestContactName;
      self.clinicData.bestContactEmail = clinic.bestContactEmail;
      self.clinicData.billingEmail = clinic.billingEmail;
      self.clinicData.invoiceEmail = clinic.invoiceEmail;

      self.clinicData.status = clinic.status;
      self.clinicData.emailVerifiedAt = clinic.emailVerifiedAt;
      self.clinicData.hasApprovedByAdmin = clinic.hasApprovedByAdmin;

      self.clinicData.financialDetails = clinic.financialDetails
        ? clinic.financialDetails
        : {
            accountHolderName: "",
            bsb: "",
            accountNumber: ""
          };

      self.clinicData.contactNumber = clinic.contactNumber
        ? clinic.contactNumber
        : "";
      self.clinicData.instagram = clinic.instagram ? clinic.instagram : "";
      self.clinicData.facebook = clinic.facebook ? clinic.facebook : "";
      self.clinicData.website = clinic.website ? clinic.website : "";
      self.clinicData.clinicAddress1 = clinic.clinicAddress1
        ? clinic.clinicAddress1
        : "";
      self.clinicData.isResidentialAddress = clinic.isResidentialAddress
        ? clinic.isResidentialAddress
        : "";
      self.clinicData.haveAdrenalineAndHyalaseInClinic = clinic.haveAdrenalineAndHyalaseInClinic
        ? clinic.haveAdrenalineAndHyalaseInClinic
        : "";
      self.clinicData.intendToSecureS4Medication = clinic.intendToSecureS4Medication
        ? clinic.intendToSecureS4Medication
        : "";
      self.clinicData.abnCertificate = clinic.abnCertificate
        ? clinic.abnCertificate
        : "";
      self.clinicData.councilPermits = clinic.councilPermits
        ? clinic.councilPermits
        : [];
      self.clinicData.poisonsCertificate = clinic.poisonsCertificate
        ? clinic.poisonsCertificate
        : [];
      self.clinicData.clinicOpeningHours = clinic.clinicOpeningHours
        ? clinic.clinicOpeningHours
        : {
            mondays: "",
            tuesdays: "",
            wednesdays: "",
            thursdays: "",
            fridays: "",
            saturdays: "",
            sundays: ""
          };
      self.clinicData.clinicFrontPhoto = clinic.clinicFrontPhoto
        ? clinic.clinicFrontPhoto
        : [];
      self.clinicData.clinicFloorPhoto = clinic.clinicFloorPhoto
        ? clinic.clinicFloorPhoto
        : [];
      self.clinicData.clinicTreatmentBedPhoto = clinic.clinicTreatmentBedPhoto
        ? clinic.clinicTreatmentBedPhoto
        : [];
      self.clinicData.clinicDrawingUpAreaPhoto = clinic.clinicDrawingUpAreaPhoto
        ? clinic.clinicDrawingUpAreaPhoto
        : [];
      self.clinicData.clinicFridgePhoto = clinic.clinicFridgePhoto
        ? clinic.clinicFridgePhoto
        : [];
      self.clinicData.clinicDrugStoragePhoto = clinic.clinicDrugStoragePhoto
        ? clinic.clinicDrugStoragePhoto
        : [];
      self.clinicData.clinicBinsPhoto = clinic.clinicBinsPhoto
        ? clinic.clinicBinsPhoto
        : [];
      self.clinicData.clinicRoomLockPhoto = clinic.clinicRoomLockPhoto
        ? clinic.clinicRoomLockPhoto
        : [];
      self.clinicData.clinicEpipenAndHyalasePhoto = clinic.clinicEpipenAndHyalasePhoto
        ? clinic.clinicEpipenAndHyalasePhoto
        : [];
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    async saveData() {
      let self = this;
      const isValid = await this.$validator.validateAll("basic");
      if (isValid) {
        await this.updateClinicDetail({
          id: this.clinicId,
          data: this.clinicData
        }).then(res => {
          self.$vs.notify({
            title: "Clinic updated",
            text: "Clinic details updated Successfully.",
            color: "success"
          });
        });
      }
    },
    async changePassword() {
      let self = this;
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetClinicPassword({
          id: this.clinicId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword
          }
        }).then(res => {
          self.password = "";
          self.confirmPassword = "";
          self.$validator.reset();
          self.$vs.notify({
            title: "Clinic password reset",
            text: "Clinic's password reset Successfully.",
            color: "success"
          });
        });
      }
    },
    respondInvitation(respondStatus) {
      this.$vs.loading();
      const params = {
        status: respondStatus,
        doctorId: this.$store.state.AppActiveUser._id,
        requestId: this.$route.params.requestId
      };
      this.$store
        .dispatch("doctor/respondClinicJoinRequest", params)
        .then(response => {
          this.$vs.notify({
            title: "Success",
            text: `Invitation ${respondStatus} Successfully.`,
            color: "success"
          });
          this.$router.push("/doctor/clinics");
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger"
          });
          this.$router.push("/doctor/clinics");
          this.$vs.loading.close();
        });
    },
    onSuccessfullyVerified(verifiedAt) {
      this.clinicData.emailVerifiedAt = verifiedAt;
    }
  },
  mounted() {
    const ut = this.$store.state.AppActiveUser.userType;
    if (ut === "superAdmin" || ut === "admin" || ut === "franchise")
      this.showHideIcon = true;
    else this.showHideIcon = false;
  },
  async created() {
    let clinicId = this.$route.params.clinicId;
    await this.getClinicDetail(clinicId);
  },
  beforeDestroy() {
    EventBus.$emit("closePopup");
  }
};
</script>

<style>
p {
  font-size: 15px;
  font-weight: 500;
}

.clinicOpeningHoursDiv {
  width: 100%;
  min-height: 75px;
  border: 2px solid #ddd;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
}

.clinicOpeningHoursDiv span {
  font-size: 15px;
  font-weight: 600;
  background: #fff;
  position: absolute;
  top: -12px;
  left: 35px;
  padding: 0 8px;
}

.uploadedImages {
  text-align: left !important;
}

.dateClass {
  margin-top: 0 !important;
}
</style>
